import React, { useEffect, useState } from "react";
import { PopupContainer, PopupContent } from "./styled";
import { Modal } from "antd";
import { useLocation } from "react-router-dom";
import { ButtonCommon } from "../../../Layout/styled";
import { Spin } from "antd";
import toast from "react-hot-toast";
declare const window: Window & typeof globalThis & { ethereum: any };
interface tokenInterface {
  tokenAddress: string;
  tokenSymbol: string;
  tokenDecimals: number;
  tokenImage: string;
}
const PopupNotice = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { pathname } = useLocation();
  const [loadingAddToken, setLoadingAddToken] = useState(false);

  useEffect(() => {
    setShowPopup(true);
  }, [pathname]);

  const handleCloseModal = () => {
    setShowPopup(false);
  };

  //add contract
  const addTokenToMetaMask = async ({
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
    tokenImage,
  }: tokenInterface) => {
    setLoadingAddToken(true);
    if (!window.ethereum) {
      console.error("MetaMask is not installed!");
      return false;
    }

    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        toast.success(`Token ${tokenSymbol} has been added to MetaMask!`);
        setLoadingAddToken(false);
        handleCloseModal();
      } else {
        toast.error("User declined to add the token.");
        setLoadingAddToken(false);
      }
      return wasAdded;
    } catch (error: any) {
      toast.error("Error adding token to MetaMask:", error);
      setLoadingAddToken(false);
      return false;
    }
  };

  return (
    <PopupContainer>
      <Modal
        title="ONI Protocol Announcement"
        open={showPopup}
        onCancel={handleCloseModal}
        footer={false}
        centered
      >
        <PopupContent>
          <p className="text-info">
            Oni Protocol are updating our smart contract in preparation for the
            upcoming audit and listing of ONI on a major CEX.
          </p>
          <ul className="list-info">
            <li>
              <span>Smart Contract Update:</span> <br /> To enhance security and
              transparency, we will be updating the ONI smart contract. ONI Ver2
              smartcontract :{" "}
              <a
                href="https://bscscan.com/address/0x7e66fCe690fCa44926748c961FB14E259F642b1C"
                target="_blank"
                rel="noopener"
                className="link"
              >
                0x7e66fCe690fCa44926748c961FB14E259F642b1C
              </a>
            </li>
            <li>
              <span>Token Conversion:</span> <br />
              All current ONI token holders will receive ONI Ver2 tokens at a
              1:1 ratio.
            </li>
            <li>
              <span>Conversion Time:</span> <br />
              The conversion will take place at 12:00 UTC on February 24, 2025.
            </li>
            <li>
              <span>Withdrawal Freeze:</span> <br />
              The withdrawal function will be temporarily frozen from February
              20 to February 24, 2025 to facilitate the system update.
            </li>
          </ul>
          <p className="text-last">
            Thank you for your understanding and continued support. For any
            questions, please contact ONI Protocol support team.
          </p>
          <div className="block-button">
            <ButtonCommon
              style={{ width: "100%" }}
              className="green"
              onClick={() =>
                addTokenToMetaMask({
                  tokenAddress: "0x7e66fCe690fCa44926748c961FB14E259F642b1C",
                  tokenSymbol: "ONI",
                  tokenDecimals: 18,
                  tokenImage:
                    "https://app.oniprotocol.io/img/Dashboard/Pool/icn_token.png",
                })
              }
            >
              {loadingAddToken ? <Spin /> : "Add token to wallet"}
            </ButtonCommon>
          </div>
        </PopupContent>
      </Modal>
    </PopupContainer>
  );
};

export default PopupNotice;
