import { useEffect, useRef, useState } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import axios from "axios";
import { convertFixed } from "../../../../utils/convertNumber";
import moment from "moment";
import { ChartData, ChartMain, ChartWrapper, TooltipChart } from "../styled";
import LoadingCommon from "../../../Loading";

const ChartCandles = ({
  height,
  selectedTimeframe,
  selectedAggregate,
}: any) => {
  const chartContainerRef = useRef<any>();
  const chartRef = useRef<any>();
  const candlestickSeriesRef = useRef<any>();
  const tooltipRef = useRef<any>();
  const [chartData, setChartData] = useState<any>([]);
  const lastPriceRef = useRef<number | null>(null);
  const [percentageChange, setPercentageChange] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [currentPrice, setCurrentPrice] = useState<number | null>(null);

  const fetchHistoricalData = async () => {
    try {
      const response = await axios.get(
        `https://api.geckoterminal.com/api/v2/networks/bsc/pools/0x4b70Ab9b532E90019ea8507903fB0EAA2A56070f/ohlcv/${selectedTimeframe}`,
        {
          params: {
            aggregate: selectedAggregate,
            limit: 500,
            currency: "usd",
          },
        }
      );

      const prices = response.data.data.attributes.ohlcv_list;

      let formattedData = prices.map((point: any) => ({
        time: point[0],
        open: point[1], // Open price
        high: point[2], // High price
        low: point[3], // Low price
        close: point[4], // Close price
        volume: point[5],
      }));

      const seenTimes = new Set();
      formattedData = formattedData.filter((dataPoint: any) => {
        if (seenTimes.has(dataPoint.time)) {
          return false;
        }
        seenTimes.add(dataPoint.time);
        return true;
      });

      formattedData.sort((a: any, b: any) => a.time - b.time);

      if (formattedData.length > 0) {
        const currentPrice = formattedData[formattedData.length - 1].close;
        setCurrentPrice(currentPrice);
        if (lastPriceRef.current !== null) {
          const previousPrice = lastPriceRef.current;
          const change = ((currentPrice - previousPrice) / previousPrice) * 100;
          setPercentageChange(change);
        }
        lastPriceRef.current = currentPrice;
      }

      setLastUpdated(moment().format("HH:mm A [on] DD/MM/YYYY"));
      setChartData(formattedData);
    } catch (error) {
      console.error("Error fetching OHLCV data from GeckoTerminal:", error);
    }
  };

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      fetchHistoricalData();
    }, 10000);

    fetchHistoricalData();

    return () => clearInterval(fetchInterval);
  }, [selectedTimeframe, selectedAggregate]);

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      layout: {
        textColor: "#9F9F9F",
        background: { color: "transparent" },
      },
      grid: {
        vertLines: {
          color: "rgba(80, 80, 80, .2)",
        },
        horzLines: {
          color: "rgba(80, 80, 80, .2)",
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      timeScale: {
        borderColor: "#4E4E4E",
        rightOffset: 2,
        // fixLeftEdge: true,
        // fixRightEdge: true,
        tickMarkFormatter: (time: number) => {
          return moment(time * 1000).format("HH:mm");
        },
      },
      height: height || 450,
      width: chartContainerRef.current.clientWidth,
    });

    chartRef.current = chart;

    const candlestickSeries = chart.addCandlestickSeries({
      upColor: "#4CAF50",
      downColor: "#FF5252",
      borderUpColor: "#4CAF50",
      borderDownColor: "#FF5252",
      wickUpColor: "#4CAF50",
      wickDownColor: "#FF5252",
      priceScaleId: "right",
      priceLineVisible: true,
      priceFormat: {
        type: "price",
        precision: 6,
        minMove: 0.000001,
      },
    });
    candlestickSeriesRef.current = candlestickSeries;

    const handleResize = () => {
      if (chartContainerRef.current) {
        const { width, height } =
          chartContainerRef.current.getBoundingClientRect();
        chart.applyOptions({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(chartContainerRef.current);

    chartContainerRef.current.addEventListener(
      "mousemove",
      (event: MouseEvent) => {
        if (!tooltipRef.current) return;

        const boundingRect = chartContainerRef.current.getBoundingClientRect();
        const x = event.clientX - boundingRect.left;
        const y = event.clientY - boundingRect.top;

        const time: any = chart.timeScale().coordinateToTime(x);
        const price = candlestickSeries.coordinateToPrice(y);

        const closestDataPoint = chartData.reduce((prev: any, curr: any) => {
          return Math.abs(curr.time - time) < Math.abs(prev.time - time)
            ? curr
            : prev;
        }, chartData[0] || { time: 0, value: 0 });

        if (time && price !== null) {
          tooltipRef.current.style.display = "block";
          tooltipRef.current.innerHTML = `ONI/WBNB: ${convertFixed(
            closestDataPoint.close
          )}`;
          tooltipRef.current.style.left = `${x}px`;
          tooltipRef.current.style.top = `${y}px`;
        } else {
          tooltipRef.current.style.display = "none";
        }
      }
    );

    chartContainerRef.current.addEventListener("mouseleave", () => {
      if (tooltipRef.current) {
        tooltipRef.current.style.display = "none";
      }
    });

    return () => {
      resizeObserver.disconnect();
      chart.remove();
    };
  }, [height, selectedTimeframe, chartData]);

  useEffect(() => {
    if (candlestickSeriesRef.current && chartData.length > 0) {
      candlestickSeriesRef.current.setData(chartData);
      chartRef.current.timeScale().fitContent();
    }
  }, [chartData]);

  return (
    <ChartWrapper>
      <LoadingCommon />
      <TooltipChart ref={tooltipRef} />
      <ChartMain ref={chartContainerRef} />
      <ChartData>
        <p>
          Current Price:{" "}
          {currentPrice ? `${convertFixed(currentPrice)} USD` : "Fetching..."}
        </p>
        <span className="color">
          {lastUpdated
            ? `Last updated: ${lastUpdated} ( Refresh every minute )`
            : "Fetching data..."}
        </span>
        {percentageChange === 0 ? (
          <span>There was nothing changed</span>
        ) : (
          <span style={{ color: percentageChange > 0 ? "#59FF9E" : "#FF5252" }}>
            {percentageChange > 0
              ? `Increased by ${percentageChange.toFixed(2)}%`
              : `Decreased by ${Math.abs(percentageChange).toFixed(2)}%`}{" "}
            since last minute.
          </span>
        )}
      </ChartData>
    </ChartWrapper>
  );
};

export default ChartCandles;
