import styled from "styled-components";
import { ButtonCommon } from "../../../Layout/styled";

export const PopupContainer = styled.div``;
export const PopupContent = styled.div`
  .text-info {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  .list-info {
    margin-bottom: 10px;
    li {
      position: relative;
      padding-left: 10px;
      &:before {
        position: absolute;
        content: "";
        top: 10px;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: #000;
        border-radius: 50%;
      }
      span {
        font-weight: 500;
        font-size: 15px;
      }
    }
    .link {
      font-weight: 700;
      color: #000;
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .text-last {
    margin-bottom: 10px;
  }
  .block-button {
    width: 200px;
    margin: 0 auto;
    ${ButtonCommon} {
      min-height: 50px;
      justify-content: center;
      width: 100%;
      padding: 15px;
      background: radial-gradient(
          105.75% 89.98% at 2.11% 14.58%,
          rgba(129, 238, 151, 0) 0%,
          rgba(129, 238, 151, 0.2) 100%
        ),
        rgba(35, 35, 35, 0.8);
      box-shadow: unset;
    }
  }
`;
