import { useContext, useEffect, useState } from "react";
import TitleCommon from "../../components/Common/Title";
import {
  BlockFrame,
  ButtonCommon,
  StatusTag,
  TextHelper,
} from "../../Layout/styled";
import {
  BlockTable,
  MiningAction,
  MiningActionBody,
  MiningActionHeader,
  MiningBlock,
  MiningBlockBody,
  MiningBlockHeader,
  MiningBlockList,
  MiningBody,
  MiningContainer,
  MiningHeader,
  MiningItem,
  MiningItemAction,
  MiningItemInfo,
  MiningLiqCreate,
  MiningReward,
  MiningTabs,
  MiningWrapper,
  MininingSelect,
} from "./styled";
import MiningStake from "./Stake";
import MiningUnstake from "./Unstake";
import MiningClaim from "./Claim";
import MiningDetail from "./Detail";
import { ContextProviderWrapper } from "../../components/Context";
import { LiquidityActions } from "../Liquidity/Add/styled";
import SelectCommon from "../../components/Common/Select";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import { LaunchpadList, LaunchpadListItem } from "../Launchpad/styled";
import BackCommon from "../../components/Common/Back";
import { ethers } from "ethers";
import web3 from "web3";
import axios from "axios";
import { convertToWei } from "../../utils/convertNumber";
import { useAccount } from "wagmi";
import {
  addressContractFarm,
  addressContractLP,
  addressContractLP_0,
} from "../../contract/Address";
import { abiPancakePair, farmABI, lpABI } from "../../contract/Pair";
import toast from "react-hot-toast";
import { gql, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { BorderAnimation } from "../../Layout/Dashboard/styled";
import getTokenInfo from "../../utils/checkInfoByAccount";
import { formatNumberMega } from "../../utils/formatNumber";
import { Tabs, TabsProps } from "antd";
import TableTransaction from "./TableTransaction";
import TableMining from "./TableMining";
import ModalSuccess from "../../components/Modal/Success";
import { CheckOutlined } from "@ant-design/icons";

declare const window: Window & typeof globalThis & { ethereum: any };

const STAKE = gql`
  mutation stake($hash: String!) {
    stake(hash: $hash)
  }
`;

const Mining = () => {
  const Web3 = new web3(window.ethereum);
  const [miningId, setMiningId] = useState(0);
  const [tabs, setTabs] = useState<string>("1");
  const [newTransactions, setNewTransactions] = useState<any>();
  const [newDataMining, setNewDataMining] = useState<any>();
  const [miningAction, setMiningAction] = useState(false);
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const [stake] = useMutation(STAKE);
  const { userProfile }: any = useSelector((state: any) => state.user);
  const getSwiperActive: any = localStorage.getItem("activeSwiper");
  const [priceBnbPool, setPriceBnbPool] = useState(0);
  const [loading, setLoading] = useState(true);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [active, setActive] = useState(0);
  const [valueInput, setValueInput] = useState(0);
  const [balanceLP, setBalanceLP] = useState("");
  const [clickedSelection, setClickedSelection] = useState("ONI/BNB");
  const [allowance, setAllowance] = useState<any>("");
  const [pendingApprove, setPendingApprove] = useState<any>(false);
  const [gasPrice, setGasPrice] = useState<any>("");
  const usdtPrice = useSelector((state: any) => state.user.usdtPrice);
  const [antiMev, setAntiMev] = useState(true);
  useEffect(() => {
    // Reset value when check and uncheck antiMev
    setValueInput(0);
  }, [antiMev]);

  const handleSwitchTab = () => {
    switch (tabs) {
      case "2":
        return <MiningUnstake />;
      case "3":
        return <MiningClaim />;
      default:
        return (
          <MiningStake
            setValueInput={setValueInput}
            valueInput={valueInput}
            priceBnbPool={priceBnbPool}
            handleFarmingLP={handleFarmingLP}
            balanceLP={balanceLP}
            allowance={allowance}
            approveFarming={approveFarming}
            isLoading={isLoading}
            pendingApprove={pendingApprove}
            setAntiMev={setAntiMev}
          />
        );
    }
  };

  // Get balance LP
  const getBalanceLP = async () => {
    try {
      const contractLP = new Web3.eth.Contract(
        lpABI,
        antiMev ? addressContractLP_0 : addressContractLP
      );
      await contractLP.methods
        .balanceOf(address)
        .call()
        .then((res: any) => {
          setBalanceLP(res);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address || antiMev) {
      getBalanceLP();
    }
  }, [address, antiMev]);

  const onChangeSelectMobile = (e: any) => {
    setClickedSelection(e);
  };

  const miningData = [
    {
      id: 1,
      imgFrom: "./img/Dashboard/mining/mining_1.png",
      valueFrom: 100,
      valueTo: 999,
      textFrom: "$100",
      textTo: "$999",
      pair: "Nebula V100-Node1",
      tvl: "$543,673.65",
      apr: "48%",
      reward: "$0.0000",
      background: !isDesktop
        ? "linear-gradient(180deg, rgba(21, 75, 31, 0.8) 0%, rgba(53, 131, 67, .8) 100%)"
        : "linear-gradient(180deg, rgba(99, 255, 128, 0.19) 0%, rgba(53, 131, 67, 0.19) 100%)",
    },
    {
      id: 2,
      imgFrom: "./img/Dashboard/mining/mining_2.png",
      valueFrom: 1000,
      valueTo: 4999,
      textFrom: "$1,000",
      textTo: "$4,999",
      pair: "Hawking DataProc-01",
      tvl: "$2,432,653.67",
      apr: "84%",
      reward: "$564.43",
      background: !isDesktop
        ? "rgba(21, 75, 77, 0.8)"
        : "rgba(48, 248, 255, 0.19)",
    },
    {
      id: 3,
      imgFrom: "./img/Dashboard/mining/mining_3.png",
      valueFrom: 5000,
      valueTo: 9999,
      textFrom: "$5,000",
      textTo: "$9,999",
      pair: "Blackhol Mining Node-2",
      tvl: "$3,232,453.54",
      apr: "108%",
      reward: "$0.0000",
      background: !isDesktop ? "#133d38" : "rgba(113, 255, 236, 0.11)",
    },
    {
      id: 4,
      imgFrom: "./img/Dashboard/mining/mining_4.png",
      valueFrom: 10000,
      valueTo: 29999,
      textFrom: "$10,000",
      textTo: "$29,999",
      pair: "Data-Processing1 Mining",
      tvl: "$2,642,879.92",
      apr: "144%",
      reward: "$0.0000",
      background: !isDesktop
        ? "rgba(38, 103, 94, 0.8)"
        : "rgba(113, 255, 236, 0.11)",
    },
    {
      id: 5,
      imgFrom: "./img/Dashboard/mining/mining_5.png",
      valueFrom: 30000,
      valueTo: 1000000000000000,
      textFrom: "",
      textTo: "More than $30,000",
      pair: "Pulsar Inference-Node2",
      tvl: "$5,890,651.63",
      apr: "240%",
      reward: "$0.0000",
      background: !isDesktop
        ? "#065c0c"
        : "linear-gradient(180deg, rgba(137, 255, 145, 0.34) 0%, rgba(5, 97, 5, 0.34) 100%)",
    },
  ];

  const miningData2 = [
    {
      id: 1,
      imgFrom: "./img/Dashboard/mining/mining_1.png",
      valueFrom: 100,
      valueTo: 999,
      textFrom: "$100",
      textTo: "$999",
      pair: "Nebula V100-Node1",
      tvl: "$543,673.65",
      apr: "48%",
      reward: "$0.0000",
      background: !isDesktop
        ? "linear-gradient(180deg, rgba(21, 75, 31, 0.8) 0%, rgba(53, 131, 67, .8) 100%)"
        : "linear-gradient(180deg, rgba(99, 255, 128, 0.19) 0%, rgba(53, 131, 67, 0.19) 100%)",
    },
    {
      id: 2,
      imgFrom: "./img/Dashboard/mining/mining_2.png",
      valueFrom: 1000,
      valueTo: 4999,
      textFrom: "$1,000",
      textTo: "$4,999",
      pair: "Hawking DataProc-01",
      tvl: "$2,432,653.67",
      apr: "84%",
      reward: "$564.43",
      background: !isDesktop
        ? "rgba(21, 75, 77, 0.8)"
        : "rgba(48, 248, 255, 0.19)",
    },
    {
      id: 3,
      imgFrom: "./img/Dashboard/mining/mining_3.png",
      valueFrom: 5000,
      valueTo: 9999,
      textFrom: "$5,000",
      textTo: "$9,999",
      pair: "Blackhol Mining Node-2",
      tvl: "$3,232,453.54",
      apr: "108%",
      reward: "$0.0000",
      background: !isDesktop ? "#133d38" : "rgba(113, 255, 236, 0.11)",
    },
    {
      id: 4,
      imgFrom: "./img/Dashboard/mining/mining_4.png",
      valueFrom: 10000,
      valueTo: 29999,
      textFrom: "$10,000",
      textTo: "$29,999",
      pair: "Data-Processing1 Mining",
      tvl: "$2,642,879.92",
      apr: "144%",
      reward: "$0.0000",
      background: !isDesktop
        ? "rgba(38, 103, 94, 0.8)"
        : "rgba(113, 255, 236, 0.11)",
    },
    {
      id: 5,
      imgFrom: "./img/Dashboard/mining/mining_5.png",
      valueFrom: 30000,
      valueTo: 1000000000000000,
      textFrom: "",
      textTo: "More than $30,000",
      pair: "Pulsar Inference-Node2",
      tvl: "$5,890,651.63",
      apr: "240%",
      reward: "$0.0000",
      background: !isDesktop
        ? "#065c0c"
        : "linear-gradient(180deg, rgba(137, 255, 145, 0.34) 0%, rgba(5, 97, 5, 0.34) 100%)",
    },
  ];

  const getPrices = async () => {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price",
        {
          params: {
            ids: "binancecoin",
            vs_currencies: "usd",
          },
        }
      );

      const bnbPriceData = response.data.binancecoin.usd;
      setBnbPrice(bnbPriceData);
      return { bnbPrice };
    } catch (error) {
      console.error("Error fetching prices:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (bnbPrice === 0) {
      getPrices();
    }
  }, [bnbPrice]);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const provider = new ethers.JsonRpcProvider(
          "https://bsc-dataseed4.binance.org/"
        );
        const pairContract = new ethers.Contract(
          addressContractLP,
          lpABI,
          provider
        );
        const reserves = await pairContract.getReserves();
        const tokenFrom = await pairContract.token0();
        const tokenTo = await pairContract.token1();
        const totalSupply = await pairContract.totalSupply();
        const reserve0 = reserves[0].toString();
        const reserve1 = reserves[1].toString();
        if (tokenFrom === "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
          setPriceBnbPool(
            (Number(reserve0) * bnbPrice * 2) / Number(totalSupply)
          );
        } else if (tokenTo === "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c") {
          setPriceBnbPool(
            (Number(reserve1) * bnbPrice * 2) / Number(totalSupply)
          );
        }
      } catch (error) {
        console.error("Error fetching price:", error);
      } finally {
        setLoading(false);
      }
    };
    if (bnbPrice !== 0) {
      fetchPrice();
    }
  }, [bnbPrice]);

  // Approve
  const getAllowance = async () => {
    try {
      const amountAllowance = await getTokenInfo(
        addressContractFarm,
        antiMev ? addressContractLP_0 : addressContractLP,
        address
      );
      setAllowance(amountAllowance.allowance);
    } catch (error) {
      setAllowance("0");
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (address || antiMev) {
      getAllowance();
    }
  }, [address, antiMev]);

  const approveFarming = async () => {
    const gasPrice = await Web3.eth.getGasPrice();
    try {
      setPendingApprove(true);
      const contract = new Web3.eth.Contract(
        abiPancakePair,
        antiMev ? addressContractLP_0 : addressContractLP
      );
      await contract.methods
        .approve(addressContractFarm, ethers.MaxUint256.toString())
        .send({ from: address, gasPrice: gasPrice.toString() })
        .then(async (res: any) => {
          setPendingApprove(false);
          await getAllowance();
        })
        .catch((err: any) => {
          console.error("Err approve", err);
          setPendingApprove(false);
        });
    } catch (error: any) {
      console.error("Error approve", error);
      setPendingApprove(false);
    }
  };

  useEffect(() => {
    const fetchGasDetails = async () => {
      try {
        const currentGasPrice = await Web3.eth.getGasPrice();
        setGasPrice(currentGasPrice);
      } catch (error) {
        console.error("Error fetching gas details:", error);
      }
    };
    fetchGasDetails();
  }, []);

  const [hashTransaction, setHashTransaction] = useState("");
  const handleFarmingLP = async () => {
    setIsLoading(true);
    if (typeof window !== "undefined" && window.ethereum) {
      const contractFarm = new Web3.eth.Contract(farmABI, addressContractFarm);

      if (!valueInput || isNaN(valueInput)) {
        throw new Error(
          "Invalid priceBnbPool value. It must be a valid number."
        );
      }
      const convertAmount: any = convertToWei(valueInput, 18);
      try {
        await contractFarm.methods
          .farms(convertAmount, antiMev ? 0 : 1)
          .send({ from: address, gasPrice: gasPrice.toString() })
          .then((res: any) => {
            if (res) {
              stake({
                variables: {
                  hash: res.transactionHash,
                },
              });
              setIsModalOpen(true);
              setHashTransaction(res.transactionHash);
              setValueInput(0);
              setIsLoading(false);
              toast.success("Stake successfully");
            }
          })
          .catch((error: any) => {
            console.log("error", error);
            toast.error("Transaction has been rejected");
            setIsLoading(false);
          });
      } catch (error) {
        console.error("Error executing transaction:", error);
        toast.error("Error executing transaction");
        setIsLoading(false);
      }
    }
  };

  const checkActiveStatus = () => {
    const inputValue = priceBnbPool * valueInput;
    for (let data of miningData) {
      if (data.id === 5 && inputValue > data.valueFrom) {
        setActive(data.id);
        return;
      } else if (inputValue >= data.valueFrom && inputValue <= data.valueTo) {
        setActive(data.id);
        return;
      }
    }
    setActive(0);
  };

  useEffect(() => {
    checkActiveStatus();
  }, [priceBnbPool, valueInput]);

  useEffect(() => {
    if (userProfile?.wallet?.transactions) {
      const dataTransactions = userProfile?.wallet?.transactions?.filter(
        (item: any) => item.walletType !== "oniPoolShare"
      );
      const reversedTransactions = [...dataTransactions]?.reverse();
      setNewTransactions(reversedTransactions);
      // const reversedTransactions = [
      //   ...userProfile?.wallet?.transactions,
      // ].reverse();
      // setNewTransactions(reversedTransactions);
    }
  }, [userProfile]);

  //data mining
  useEffect(() => {
    if (userProfile?.wallet?.investments) {
      const reversedInvestments = [
        ...userProfile?.wallet?.investments,
      ].reverse();
      setNewDataMining(reversedInvestments);
    }
  }, [userProfile]);

  //tabs table
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Mining",
      children: <TableMining newDataMining={newDataMining} />,
    },
    {
      key: "2",
      label: "Transaction",
      children: <TableTransaction newTransactions={newTransactions} />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <MiningContainer>
      <ModalSuccess
        hash={hashTransaction}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <MiningWrapper>
        {!isDesktop ? (
          <>
            {miningAction ? (
              <div>
                <BackCommon
                  backFunction={() => {
                    setMiningAction(false);
                  }}
                />
                <MiningActionHeader>
                  <TitleCommon
                    title="Stake ONI LP Token & Mining"
                    description={
                      <p>
                        Period:
                        <span
                          style={{
                            color: "#81EE97",
                            marginLeft: "5px",
                          }}
                        >
                          24 Months
                        </span>
                      </p>
                    }
                  />
                </MiningActionHeader>
                {handleSwitchTab()}
              </div>
            ) : (
              <>
                {miningId !== 0 ? (
                  <MiningStake
                    setValueInput={setValueInput}
                    valueInput={valueInput}
                    priceBnbPool={priceBnbPool}
                    handleFarmingLP={handleFarmingLP}
                    balanceLP={balanceLP}
                    allowance={allowance}
                    approveFarming={approveFarming}
                    isLoading={isLoading}
                    miningData={miningData}
                    miningId={miningId}
                    setMiningId={setMiningId}
                    backFunction={setMiningId}
                    setAntiMev={setAntiMev}
                  />
                ) : (
                  <>
                    <MiningHeader>
                      <TitleCommon title="ONI Protocol Mining" description="" />
                    </MiningHeader>
                    <MininingSelect>
                      <SelectCommon
                        data={[
                          {
                            title: "ONI/BNB",
                            img: "./img/Dashboard/Liquidity/oni_bnb_pair.png",
                          },
                          {
                            title: "ONI/USDT",
                            img: "./img/Dashboard/Liquidity/oni_usdt_pair.png",
                          },
                        ]}
                        onChange={onChangeSelectMobile}
                        suffixIcon={
                          <img
                            width={33}
                            height={32}
                            src="./img/Common/select_btn.png"
                            loading="lazy"
                            alt="icon"
                          />
                        }
                      />
                    </MininingSelect>
                    <TextHelper
                      style={{
                        marginTop: "-15px",
                        marginBottom: "15px",
                      }}
                    >
                      <p>Swipe & choose your mining plan</p>
                    </TextHelper>
                    <LaunchpadList>
                      <Swiper
                        effect={"cards"}
                        grabCursor={true}
                        modules={[EffectCards]}
                        className="cards"
                        initialSlide={!isDesktop ? getSwiperActive : "0"}
                      >
                        {clickedSelection === "ONI/BNB" ? (
                          <>
                            {miningData.map((item, index) => {
                              return (
                                <SwiperSlide key={index}>
                                  <LaunchpadListItem
                                    style={{
                                      background: item.background,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setMiningId(item.id);
                                    }}
                                  >
                                    <MiningBlockHeader>
                                      <div>
                                        <div>
                                          <figure>
                                            <img
                                              width={37}
                                              height={35}
                                              src={item.imgFrom}
                                              alt="icon"
                                              loading="lazy"
                                            />
                                          </figure>
                                        </div>
                                        <div>
                                          <p>{item.pair}</p>
                                          <p>
                                            {item.textFrom} {`-`} {item.textTo}
                                          </p>
                                        </div>
                                      </div>
                                      {userProfile?.wallet?.totalStaking &&
                                      userProfile.wallet.totalStaking >=
                                        item.valueFrom &&
                                      userProfile.wallet.totalStaking <=
                                        item.valueTo ? (
                                        <div>
                                          <CheckOutlined
                                            style={{
                                              fontSize: "25px",
                                              fontWeight: "bold",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </MiningBlockHeader>
                                    <MiningBlockBody>
                                      <MiningItemInfo>
                                        <div>
                                          <p>TVL</p>
                                          <span>{item.tvl}</span>
                                        </div>
                                        <div>
                                          <p>APR</p>
                                          <span>{item.apr}</span>
                                        </div>
                                      </MiningItemInfo>
                                      <MiningReward>
                                        <div>
                                          <p>Reward</p>
                                          <img
                                            width={14}
                                            height={14}
                                            src="./img/Common/hint.svg"
                                            alt="icon"
                                            loading="lazy"
                                          />
                                        </div>
                                        <p>
                                          $
                                          {userProfile?.wallet?.totalStaking &&
                                          userProfile.wallet.totalStaking >=
                                            item.valueFrom &&
                                          userProfile.wallet.totalStaking <=
                                            item.valueTo
                                            ? formatNumberMega(
                                                userProfile.wallet
                                                  .totalRewardStaking *
                                                  usdtPrice
                                              )
                                            : "0.0000"}
                                        </p>
                                      </MiningReward>
                                    </MiningBlockBody>
                                  </LaunchpadListItem>
                                </SwiperSlide>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {miningData2.map((item, index) => {
                              return (
                                <SwiperSlide key={index}>
                                  <LaunchpadListItem
                                    style={{
                                      background: item.background,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setMiningId(item.id);
                                    }}
                                  >
                                    <MiningBlockHeader>
                                      <div>
                                        <div>
                                          <figure>
                                            <img
                                              width={37}
                                              height={35}
                                              src={item.imgFrom}
                                              alt="icon"
                                              loading="lazy"
                                            />
                                          </figure>
                                        </div>
                                        <div>
                                          <p>{item.pair}</p>
                                          <p>
                                            {item.valueFrom} {`-`}{" "}
                                            {item.valueTo}
                                          </p>
                                        </div>
                                      </div>
                                    </MiningBlockHeader>
                                    <MiningBlockBody>
                                      <MiningItemInfo>
                                        <div>
                                          <p>TVL</p>
                                          <span>{item.tvl}</span>
                                        </div>
                                        <div>
                                          <p>APR</p>
                                          <span>{item.apr}</span>
                                        </div>
                                      </MiningItemInfo>
                                      <MiningReward>
                                        <div>
                                          <p>Reward</p>
                                          <img
                                            width={14}
                                            height={14}
                                            src="./img/Common/hint.svg"
                                            alt="icon"
                                            loading="lazy"
                                          />
                                        </div>
                                        <p>{item.reward}</p>
                                      </MiningReward>
                                    </MiningBlockBody>
                                  </LaunchpadListItem>
                                </SwiperSlide>
                              );
                            })}
                          </>
                        )}
                      </Swiper>
                    </LaunchpadList>
                    <MiningTabs>
                      <Tabs
                        defaultActiveKey="1"
                        items={items}
                        onChange={onChange}
                      />
                    </MiningTabs>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {miningId !== 0 ? (
              <MiningDetail backFunction={setMiningId} />
            ) : (
              <>
                <BlockFrame>
                  <MiningHeader>
                    <TitleCommon
                      title="ONI Protocol Mining"
                      description="Select Node/GPUs and start Mining with ONI Protocol"
                    />
                  </MiningHeader>
                  <MiningBody>
                    <MiningBlock>
                      <MiningBlockHeader>
                        <div>
                          <figure
                            style={{
                              marginRight: "-10px",
                            }}
                          >
                            <img
                              width={37}
                              height={36}
                              src="./img/Common/oni_icon.png"
                              alt="icon"
                              loading="lazy"
                            />
                          </figure>
                          <figure>
                            <img
                              width={36}
                              height={36}
                              src="./img/Common/bnb_icon.png"
                              alt="icon"
                              loading="lazy"
                            />
                          </figure>
                        </div>
                        <p>ONI/BNB Mining Packages</p>
                      </MiningBlockHeader>
                      <MiningBlockList>
                        {miningData.map((item, index) => {
                          return (
                            <li
                              className={active === item.id ? "active" : ""}
                              key={index}
                              style={{
                                background: item.background,
                              }}
                            >
                              <BorderAnimation />
                              <MiningItem>
                                <MiningBlockHeader>
                                  <div>
                                    <div>
                                      <figure>
                                        <img
                                          width={37}
                                          height={34}
                                          src={item.imgFrom}
                                          alt="icon"
                                          loading="lazy"
                                        />
                                      </figure>
                                    </div>
                                    <div>
                                      <p>{item.pair}</p>
                                      <p>
                                        {item.textFrom} {item.id < 5 && `-`}{" "}
                                        {item.textTo}
                                      </p>
                                    </div>
                                  </div>
                                  {userProfile?.wallet?.totalStaking &&
                                  userProfile.wallet.totalStaking >=
                                    item.valueFrom &&
                                  userProfile.wallet.totalStaking <=
                                    item.valueTo ? (
                                    <div>
                                      <CheckOutlined
                                        style={{
                                          fontSize: "25px",
                                          fontWeight: "bold",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </MiningBlockHeader>
                                <MiningBlockBody>
                                  <MiningItemInfo>
                                    <div>
                                      <p>TVL</p>
                                      <span>{item.tvl}</span>
                                    </div>
                                    <div>
                                      <p>APR</p>
                                      <span>{item.apr}</span>
                                    </div>
                                  </MiningItemInfo>
                                  <MiningReward>
                                    <div>
                                      <p>Reward</p>
                                      <img
                                        width={14}
                                        height={14}
                                        src="./img/Common/hint.svg"
                                        alt="icon"
                                        loading="lazy"
                                      />
                                    </div>
                                    <p>
                                      $
                                      {userProfile?.wallet?.totalStaking &&
                                      userProfile.wallet.totalStaking >=
                                        item.valueFrom &&
                                      userProfile.wallet.totalStaking <=
                                        item.valueTo
                                        ? formatNumberMega(
                                            userProfile.wallet
                                              .totalRewardStaking * usdtPrice
                                          )
                                        : "0.0000"}
                                    </p>
                                  </MiningReward>
                                </MiningBlockBody>
                              </MiningItem>
                            </li>
                          );
                        })}
                      </MiningBlockList>
                    </MiningBlock>
                    <MiningTabs>
                      <Tabs
                        defaultActiveKey="1"
                        items={items}
                        onChange={onChange}
                      />
                    </MiningTabs>
                  </MiningBody>
                </BlockFrame>
              </>
            )}
            {isDesktop && (
              <MiningAction>
                <MiningActionHeader>
                  <TitleCommon
                    title="Stake ONI LP Token & Mining"
                    description={
                      <p>
                        Period:
                        <span
                          style={{
                            color: "#81EE97",
                            marginLeft: "5px",
                          }}
                        >
                          24 Months
                        </span>
                      </p>
                    }
                  />
                </MiningActionHeader>
                <MiningActionBody>{handleSwitchTab()}</MiningActionBody>
              </MiningAction>
            )}
            {!isDesktop && (
              <LiquidityActions>
                <ButtonCommon className="green">Stake</ButtonCommon>
              </LiquidityActions>
            )}
          </>
        )}
      </MiningWrapper>
    </MiningContainer>
  );
};

export default Mining;
